<template>
<!-- v-wechat-title="pageTitle" -->
  <div id="NewsNotice">
      <ul class="tab-nav">
        <li
          :class="{ 'tab-active': activeIndex === index }"
          v-for="(item, index) in netHallNoticeType"
          :key="item.code"
          @click="tabNavClick(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="news-list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh('@refresh')">
          <van-list
            v-model="loading"
            error-text="数据加载失败"
            :finished="finished"
            :finished-text="finishedText"
            @load="onReachBottom"
            :immediate-check="dictory ? false : true"
          >
            <div
              class="news-box"
              v-for="(item, index) in newsList"
              :key="index"
              @click="toNewsDetail(item)"
            >
              <img class="news-left" :src="'data:' + item.fileType + ';base64,' + item.noticeCoverStream" alt="">
              <ul class="news-right">
                <li>{{ item.noticeTitle }}</li>
                <li>{{ item.releaseTime }}</li>
              </ul>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
  </div>
</template>

<script>
// /**
  const tabData = [
    {id: 0, label: '企业新闻'},
    {id: 1, label: '行业动态'},
    {id: 2, label: '停水公告'},
  ];
  for (let i = 0; i < 10; i++) {
      tabData.push({id: i + 3, label: `水质公告${i + 1}`});
  }

  const newsData = [
    {
      id: 1,
      imgUrl: '',
      title: '“廉洁进项目”创清廉企业工11二体UR饿哦IE荣我和个人光和热光和热high俄日',
      time: '2021/11/09'
    },
    {
      id: 2,
      imgUrl: '',
      title: '严阵以待 做好冬季防冻保供22太厉害过扩军或绿付军过扩军军军军付过军绿扩军扩军军',
      time: '2021/11/09'
    },
    {
      id: 3,
      imgUrl: '市水务集团举行《创新与创33我认为附加费很少看到九分裤圣诞节封闭式的分别是都不见得大部分时间的',
      title: '“廉洁进项目”创清廉企业工二体UR饿哦IE荣我和个人光和热光和热high俄日',
      time: '2021/11/09'
    }
  ];
  for (let i = 0; i < 17; i++) {
      newsData.push({
        id: i + 3,
        imgUrl: '',
        title: `${i + 1}“廉洁进项目”创清廉企业工二体UR饿哦IE荣我和个人光和热光和热high俄日`,
        time: '2021/11/09'
      });
  }
// */

import { mapState } from 'vuex';
import store from '@/store';
import { selectNoticePageForUser } from '@/api/notice';
export default {
  data() {
    return {
      activeIndex: 0,
      noticeType: undefined,
      tabData,
      // tabList: tabData,
      tabList: [],
      newsData,
      // newsList: newsData,
      newsList: [],
      currentPage: 1,
      pageSize: 20, // 每页条数
      totalNum: 0, // 总条数
      finished: false,
      finishedText: '',
      refreshing: false,
      loading: false,
      defaultImg: require("./gonggao.png"),
      // pageTitle: ''
    }
  },
  computed: {
    ...mapState('dict', {
      dictory: (state) => state.dictory,
    }),
    netHallNoticeType() {
      if (this.dictory) {
        let data = this.dictory.NetHallNoticeType.child;
        return data;
      }
      return null;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.noticeType && !from.name) {
        vm.noticeType = to.query.noticeType; // 需要保留，在通过链接第一次进来时，netHallNoticeType 为 null，mounted 触发的请求在 loadData 会被 return掉
        localStorage.setItem('noticeType', to.query.noticeType)
        // if (!from.name) {
        //   vm.getDict();
        // }
      }
      vm.getDict();
    })
  },
  mounted() {
    /**
     * 需要保留，从链接进入公告列表，再进入详情，再返回列表，不会再走 beforeRouteEnter 请求，
     * 只走 mounted，需要读取上次由列表进入详情，存储的 noticeType
     */
    this.noticeType = localStorage.getItem('noticeType');
    // 判断需要保留，从首页等路由方式进入公告列表，也不会走 beforeRouteEnter ，读取 noticeType
    if (this.noticeType) {
      this.activeIndex = this.netHallNoticeType && this.netHallNoticeType.findIndex((item) => item.value === this.noticeType);
    }
    // this.onRefresh('mounted');
  },
  methods: {
    async getDict() {
      // 避免从详情页返回列表页，还会再调字典
      // 已经有 from.name 判断 不需要了
      if (!this.dictory) {
        await store.dispatch('dict/GenerateDictory');
      }
      if (this.noticeType) {
        this.activeIndex = this.netHallNoticeType && this.netHallNoticeType.findIndex((item) => item.value === this.noticeType);
      }
      // this.pageTitle = this.netHallNoticeType && this.netHallNoticeType.find((item) => item.value === this.noticeType).name;
      this.onRefresh('getDict');
    },
    toNewsDetail(newsItem) {
      this.$router.push({
        path: '/NewsNotice/NewsDetail',
        query: {
          noticeId: newsItem.noticeId,
          noticeType: this.noticeType,
          requestFlag: false
        }
      })
    },
    // 切换tab
    tabNavClick(item, index) {
      // this.pageTitle = this.netHallNoticeType && this.netHallNoticeType.find((val) => val.value === item.value).name;
      this.noticeType = item.value;
      localStorage.setItem('noticeType', item.value)
      this.activeIndex = index;
      this.newsList = []
      this.onRefresh('tabNavClick');
    },
    // 上拉加载，滚动条与底部距离小于 offset 时触发
    onReachBottom() {
      ++this.currentPage;
      this.loadData('downRefresh');
    },
    // 首次加载和下拉刷新
    onRefresh(who) {
      console.log(who, 'onRefresh')
      this.currentPage = 1;
      this.finished = false;
      this.loadData();
    },
    async loadData(type) {
      console.log(type, 'loadData')
      if (!this.netHallNoticeType) return false;
      
      this.loading = true;
      let params = {
        noticeType: this.noticeType ? this.noticeType : this.netHallNoticeType[this.activeIndex].value,
        page: {
          current: this.currentPage,
          size: this.pageSize
        }
      }
      const { status, resultData } = await selectNoticePageForUser(params);
      if (status === 'complete') {

        if (type === 'downRefresh') {
          this.newsList = [...this.newsList, ...resultData.records];
        } else {
          this.newsList = resultData.records || [];
          this.refreshing = false; // 关闭顶部加载中···
        }

        this.loading = false; // 关闭底部加载中...

        // 判断返回数据长度等于pageSize，底部显示数据加载完成
        if (this.newsList.length <= this.pageSize) {
          this.finishedText = '数据加载完成';
        }

        if (this.newsList.length === Number(resultData.total)) {
          this.finished = true;
          this.finishedText = '没有更多了';
        }
      } else {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped lang="less">
#NewsNotice {
  height: 100vh;
  font-size: 14px;
  background-color: #fff;
  .van-nav-bar {
    border-bottom: 1px solid #ececec;
    height: 50px;
    margin-bottom: 14px;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #000000;
    height: 16px;
  }

  /deep/ .van-nav-bar__title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
  }

  /deep/ .van-nav-bar__text {
    font-size: 16px;
    color: #4884F0
  }

  .tab-nav {
    display: flex;
    height: 56px;
    white-space: nowrap;
    overflow-x: auto;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 18px;
      box-sizing: border-box;
    }
    .tab-active {
      font-weight: 500;
      color: #4884F0;
      border-bottom: 2px solid #4884F0;
    }
  }
  
  .news-list {
    padding: 16px 15px;
    box-sizing: border-box;
    height: calc(100vh - 56px);
    overflow: auto;
    .news-box {
      padding-top: 20px;
      display: flex;
      .news-left {
        width: 100px;
        height: 70px;
        border-radius: 6px;
      }
      .news-right {
        padding-left: 10px;
        width: calc(100% - 110px);
        display: flex;
        flex: 1;
        flex-direction: column;
        li:first-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        li:last-child {
          padding-top: 10px;
          color: #999;
        }
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

</style>